import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/ads/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/ads/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/ads/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/ads/del',
        method: 'post',
        data
    })
}

export function listAllByAppId(data) {
    return request({
        url: '/advert/ads/listAllByAppId',
        method: 'post',
        data
    })
}
export function getAdTypeApi(data) {
    return request({
        url: '/advert/adType/list',
        method: 'post',
        data
    })
}
export function publishAd(data) {
    return request({
        url: '/advert/appProfit/publish',
        method: 'post',
        data
    })
}
export function createTabs(data) {
    return request({
        url: '/advert/appProfit/create2',
        method: 'post',
        data
    })
}

export function categoryAdd(data) {
    return request({
        url: '/advert/category/add',
        method: 'post',
        data
    })
}

export function categoryList(data) {
    return request({
        url: '/advert/category/list',
        method: 'post',
        data
    })
}

export function categoryEdit(data) {
    return request({
        url: '/advert/category/edit',
        method: 'post',
        data
    })
}

export function categoryDel(data) {
    return request({
        url: '/advert/category/del',
        method: 'post',
        data
    })
}

export function categoryListAll(data) {
    return request({
        url: '/advert/category/listAll',
        method: 'post',
        data
    })
}

export function tagAdd(data) {
    return request({
        url: '/advert/tag/add',
        method: 'post',
        data
    })
}

export function tagList(data) {
    return request({
        url: '/advert/tag/list',
        method: 'post',
        data
    })
}

export function tagEdit(data) {
    return request({
        url: '/advert/tag/edit',
        method: 'post',
        data
    })
}

export function tagDel(data) {
    return request({
        url: '/advert/tag/del',
        method: 'post',
        data
    })
}

export function tagListAll(data) {
    return request({
        url: '/advert/tag/listAll',
        method: 'post',
        data
    })
}


